<template>
    <Dialog
        v-model:visible="dialogFaltaConsulta"
        :style="{ width: '400px' }"
        header="Pré-solicitação"
        :modal="true"
        :closable="false"
        class="p-fluid"
    >
        <div>
            <span>Deseja alterar o status para falta consulta?</span>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="onClickClose" />
            <Button
                label="Salvar"
                icon="pi pi-check"
                :loading="loadingSave"
                :disabled="loadingSave"
                class="p-button-outlined p-button-success"
                @click="onClickFaltaConsulta"
            />
        </template>
    </Dialog>
</template>
<script>
import StatusAgendamento from '@/enums/StatusAgendamento';
import BaseService from '@/services/BaseService';
import { showError } from '../../../utils/Toast';

export default {
    emits: ['onClose', 'onLoad', 'update:visible'],
    props: ['agendamento', 'visible'],
    mounted() {
        this.$serviceAgendamentoStatus = new BaseService('/agendamento_status');
        this.$serviceCliente = new BaseService('customers');
        this.getCliente();
    },
    data() {
        return {
            loadingSave: false,
            cliente: {}
        };
    },
    computed: {
        dialogFaltaConsulta: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit('update:visible', value);
            }
        }
    },
    methods: {
        async onClickFaltaConsulta() {
            try {
                this.loadingSave = true;

                const descricao = this.respostaStatus ? `Pendência resolvida: ${this.respostaStatus}` : '';
                if (
                    this.agendamento.status.id == StatusAgendamento.AGUARDANDO_CONF_COMPARECIMENTO ||
                    this.agendamento.status.id == StatusAgendamento.PENDENCIA_PRESTADOR ||
                    this.agendamento.status.id == StatusAgendamento.AGENDADO ||
                    this.agendamento.status.id == StatusAgendamento.DIA_DA_CONSULTA
                ) {
                    const { data } = await this.$serviceAgendamentoStatus.save({
                        descricao,
                        agendamento: this.agendamento,
                        status: { id: StatusAgendamento.FALTA_CONSULTA }
                    });

                    for (const erro of data?.erros || []) {
                        this.$toast.add({ severity: 'warn', summary: erro, life: 5000 });
                    }
                } else {
                    await this.$serviceAgendamentoStatus.save({
                        descricao,
                        agendamento: this.agendamento,
                        status: { id: StatusAgendamento.PENDENCIA_PRESTADOR }
                    });
                }

                this.loadingSave = false;
                this.$emit('onLoad');
                this.$emit('onClose');
                if (this.cliente.limiteReagendamento && this.agendamento.quantidadeFaltaConsulta >= this.cliente.limiteReagendamento) {
                    this.$toast.add({
                        severity: 'success',
                        summary: `Registro atualizado com sucesso, o limite de
                        reagendamentos por falta consulta deste cliente foi
                        atingido, o mesmo não será reagendado novamente`,
                        life: 3000
                    });
                } else {
                    this.$toast.add({ severity: 'success', summary: 'Registro atualizado com sucesso', life: 3000 });
                }
            } catch (err) {
                this.loadingSave = false;
                this.$emit('onClose');
                showError(this.$toast, err);
            }
        },
        async getCliente() {
            const { data } = await this.$serviceCliente.findById(this.agendamento.idCliente);
            this.cliente = data;
        },
        onClickClose() {
            this.$emit('onClose');
        }
    }
};
</script>
